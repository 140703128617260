"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    CREATE_ARTICLE_FULFILLED: 'article/CREATE_ARTICLE_FULFILLED',
    CREATE_ARTICLE_REJECTED: 'article/CREATE_ARTICLE_REJECTED',
    CREATE_ARTICLE_PENDING: 'article/CREATE_ARTICLE_PENDING',
    CLEAR_ARTICLE: 'article/CLEAR_ARTICLE',
    PUT_POLL_FULFILLED: 'article/PUT_POLL_FULFILLED',
    PUT_POLL_REJECTED: 'article/PUT_POLL_REJECTED',
    PUT_POLL_PENDING: 'article/PUT_POLL_PENDING',
    SET_CURRENT_ARTICLE: 'article/SET_CURRENT_ARTICLE',
    CREATE_ENRICHMENTS_FULFILLED: 'article/CREATE_ENRICHMENTS_FULFILLED',
    CREATE_ENRICHMENTS_REJECTED: 'article/CREATE_ENRICHMENTS_REJECTED',
    CREATE_ENRICHMENTS_PENDING: 'article/CREATE_ENRICHMENTS_PENDING'
};
