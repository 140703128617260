import requestStatus from '@src/utils/helpers/requestStatus';

export default {
  items: [],
  salesposters: undefined,
  requestStatus: requestStatus.pending,
  putPollRequestStatus: requestStatus.idle,
  currentArticle: '',
  requestEnrichmentsStatus: requestStatus.idle
};
