import requestStatus from '@src/utils/helpers/requestStatus';
import { isPollComponent } from '@server/helpers/capiArticles/guards';
import types from './types';
import initialState from './initialState';

const addPollResults = (items, { articleId, pollId, polls }) =>
  items.map(item => {
    if (item.id === articleId) {
      const bodyContentWithResults = item.bodyContent.map(el => {
        if (isPollComponent(el) && el.pollId === pollId) {
          const { answers } =
            polls.polls.find(poll => poll.id === pollId) || {};
          return {
            ...el,
            answers
          };
        }
        return el;
      });
      return {
        ...item,
        bodyContent: bodyContentWithResults
      };
    }
    return item;
  });

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ARTICLE_FULFILLED: {
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: action.response.id,
            error: action.response.error,
            title: action.response.title,
            lead: action.response.lead,
            topImage: action.response.topImage,
            topEmbed: action.response.topEmbed,
            authors: action.response.authors,
            categories: action.response.categories,
            tags: action.response.tags,
            bodyContent: action.response.bodyContent,
            published: action.response.published,
            updated: action.response.updated,
            topVideo: action.response.topVideo,
            canonicalUrl: action.response.canonicalUrl,
            metaData: action.response.metaData,
            isPaid: action.response.isPaid,
            noindex: action.response.noindex
          }
        ],
        salesposters: action.response.salesposters,
        requestStatus: requestStatus.fulfilled
      };
    }
    case types.CREATE_ARTICLE_PENDING: {
      return {
        ...state,
        requestStatus: requestStatus.pending
      };
    }
    case types.CREATE_ARTICLE_REJECTED: {
      return {
        ...state,
        error: {
          message: action.error.message
        },
        requestStatus: requestStatus.rejected
      };
    }
    case types.PUT_POLL_PENDING: {
      return {
        ...state,
        putPollRequestStatus: requestStatus.pending
      };
    }
    case types.PUT_POLL_FULFILLED: {
      return {
        ...state,
        putPollRequestStatus: requestStatus.fulfilled
      };
    }
    case types.PUT_POLL_REJECTED: {
      return {
        ...state,
        putPollRequestStatus: requestStatus.rejected
      };
    }
    case types.CREATE_ENRICHMENTS_FULFILLED: {
      return {
        ...state,
        items: addPollResults(state.items, action.response),
        requestEnrichmentsStatus: requestStatus.fulfilled
      };
    }
    case types.CREATE_ENRICHMENTS_PENDING: {
      return {
        ...state,
        requestEnrichmentsStatus: requestStatus.pending
      };
    }
    case types.CREATE_ENRICHMENTS_REJECTED: {
      return {
        ...state,
        error: {
          message: action.error.message
        },
        requestEnrichmentsStatus: requestStatus.rejected
      };
    }
    case types.SET_CURRENT_ARTICLE: {
      return {
        ...state,
        currentArticle: action.response.id
      };
    }
    case types.CLEAR_ARTICLE: {
      return {
        ...initialState,
        id: state.id
      };
    }
    default:
      return state;
  }
};

export default articleReducer;
